import React from 'react';
import { Box, colors, Grid } from '@material-ui/core';
import { CardCategoryLink } from 'components/organisms';
import { graphql, useStaticQuery } from 'gatsby';

export default function Category() {
  const data = useStaticQuery(graphql`
    query {
      integration: allContentfulIntegration {
        edges {
          node {
            title
            body {
              body
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      
          <div>
              <Box marginBottom={2} display="flex" justifyContent="space-evenly" alignItems="center" padding={2} border="0px solid #ccc" borderRadius="4px">
                
                    <Grid container spacing={2}>
                    {data.integration.edges.map((edge) => {
                      return (
                      <Grid item xs={4} >
                        <CardCategoryLink
                          title={edge.node.title}
                          subtitle={edge.node.body.body}
                          fontIconClass="fas fa-pen-nib"
                          color={colors.blue}
                          withShadow
                          liftUp
                        />
                      </Grid>
                        )
                      })}
                    </Grid>
                
              </Box>
          </div>
      
    </div>
    
  );
}