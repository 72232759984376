import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  price: {
    color: theme.palette.text.primary,
    fontSize: 32,
    fontWeight: 'normal',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
    },
  },
  disclimer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1 / 2, 1),
    background: theme.palette.alternate.main,
    display: 'inline-block',
  },
  image: {
    maxWidth: 600,
  },
}));

const Hero = props => {
  const data = useStaticQuery(graphql`
  query {
    pages: allContentfulPages {
      edges {
        node {
          title
          slug
        }
      }
    }
    header: allContentfulHeader(filter: {id: {eq: "e60e3c25-d7cc-54be-abd3-2ac5d24b63e7"}}) {
      edges {
        node {
          title
          subTitle
          slug
          firstButton
          secondButton
          image {
            file {
              contentType
              fileName
              url
            }
          }
        }
      }
    }
  }
`);
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      {data.header.edges.map((edge) => {
        return (
          <Grid
            container
            justify="space-between"
            spacing={isMd ? 4 : 2}
            direction={isMd ? 'row' : 'column-reverse'}
          >
          <Grid item xs={12} md={6} data-aos={'fade-up'}>
            <SectionHeader
              title={edge.node.subTitle}
              subtitle={<span className={classes.price}> </span>}
              // ctaGroup={[
              //   <Button variant="contained" color="primary" size="large">
              //     {edge.node.firstButton}
              //   </Button>,
              // ]}
              align="left"
              data-aos="fade-up"
              titleVariant="h3"
            />
            <div className={classes.disclimer} data-aos="fade-up">
              {/* <Typography variant="subtitle1" color="textSecondary">
                $60 Apple Music gift card with purchase of select Beats products.*
              </Typography> */}
            </div>
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
          <Image
            width="450px"
            src={edge.node.image.file.url}
            srcSet={edge.node.image.file.url}
            // srcSet="https://assets.maccarianagency.com/the-front/photos/ecommerce/hero-cover@2x.png 2x"
            alt="Headphones"
            className={classes.image}
          />
        </Grid>
      </Grid>
        )
      
      })}
      
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
